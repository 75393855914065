$module: 'accordion';

.#{$module} {

    &__headline {
        display: flex;
    }

    &__desc {
        padding-left: 3em;
        height: 0;
        overflow: hidden;
        transition: height 0.5s;
        font-size: 1.1em;
    }

    &__title {
        flex: 1 1 auto;
        padding-left: 1em;
        font-size: 1.3em;
    }

    &__container {
        margin-bottom: 1em;
        border-bottom: 1px solid $light-grey;
        padding-bottom: 1em;
        cursor: pointer;

        @include breakpoint(tablet-portrait) {
            margin-bottom: 1.5em;
            padding-bottom: 1.5em;
        }

        &.active {
            .plus-icon {
                transform: rotate(180deg);
                &:after {
                    transition: opacity 0.3s;
                    opacity: 0;
                }
            }
        }
        .plus-icon {
            @include plus-icon(25px, $black);

            @include breakpoint(tablet-portrait) {
                @include plus-icon(30px, $black);
            }
        }
    }
}

