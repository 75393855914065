$module: 'navigationItems';

.#{$module} {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;

    @include breakpoint(tablet-landscape) {
        flex-flow: row;
    }
}