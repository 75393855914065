$module: 'sectionDivider';
$heightDivider: 30px;

.#{$module} {
    position: relative;
    text-align: center;
    height: $heightDivider;
    margin: 1em 0;

    @include breakpoint(tablet-portrait) {
        margin: 2em 0;
    }

    &__title {
        display: inline-block;
        background: $white;
        position: relative;
        z-index: 2;
        width: auto;
        padding: 0 1em;
    }

    &__line {
        position: absolute;
        top: $heightDivider / 2;
        z-index: 1;
        height: 1px;
        width: 100%;
        background-color: $medium-grey;
    }
}