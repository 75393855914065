//import commont style for the app

@import './common/sass/main/main';
@import './App';
@import './containers/header/header';
@import './containers/home/home';
@import './containers/Layout/Layout';
@import './containers/navigation/navigationItems/navigationItems';
@import './containers/navigation/navigationItems/navigationItem/navigationItem';
@import './containers/navigation/sideDrawer/sideDrawer';
@import './containers/prodotti/prodotti';
@import './containers/servizi/servizi';
@import './containers/storia/storia';
@import './containers/contatti/contatti';
@import './components/accordion/accordion';
@import './components/burgerMenu/burgerMenu';
@import './components/cta/cta';
@import './components/footer/footer';
@import './components/img/img';
@import './components/paragraph/paragraph';
@import './components/sectionDivider/sectionDivider';
@import './components/ui/backdrop/backdrop';
@import './components/ui/button/button';
@import './components/video/video';
@import './components/date/date';
@import './components/cookieBanner/cookieBanner';