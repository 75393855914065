*,
*:before,
*:after {
    box-sizing: inherit;
    outline: none;
}

html {
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
    box-sizing: border-box;
}

body {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 87.5%;  // 14px
    color: $col-text;
    min-height: 100%;
    word-wrap: break-word;
    hyphens: none;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); // Prevents default blue highlight for focus on mobiles.
    -webkit-tap-highlight-color: transparent;  // i.e. Nexus5/Chrome and Kindle Fire HD 7''

    @include breakpoint(desktop) {
        font-size: 100%;  // 16px
    }
}

// Remove default fieldset styles
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical; // Allow only vertical resizing of textareas.
    overflow: auto;  // fix to remove scroll bar on IE
}

select {
    word-wrap: normal;
    hyphens: none;
}

button,
a {
    cursor: pointer;
    outline: none;
}

a {
    text-decoration: none;
}

// A better looking default horizontal rule
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

input::-ms-clear {
    display: none;
}

// Prevents Firefox 26+ UserAgent styling.
input:invalid {
    box-shadow: none;
}

// Firefox puts an inner border on buttons
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input,
button {
    font-family: 'Nunito Sans', sans-serif;
}

table {
    width: 100%;
    border: 0;
    border-collapse: collapse;
}