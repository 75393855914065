$module: 'contatti';

.#{$module} {
    padding: 1.5em 2em;
    max-width: 1280px;
    margin: 0 auto;

    &__map {
        height: 400px;
    }

    &__tel {
        &::before {
            margin-right: 1em;
        }
    }

    &__mail {
        &::before {
            margin-right: 1em;
        }
    }

    &__sezione {
        margin-top: 2em;

        a:visited,
        a:focus, a {
            color: $col-text;
        }

        span {
            font-weight: bold;
        }
    }
}