html {
    width: 100vw;
    max-width: 100%;
    height: 100%;
}

body,
fieldset,
figure,
p,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

body {
    height:100%;
}

// Remove the gap between audio, canvas, iframes,
// images, videos and the bottom of their containers:
// https://github.com/h5bp/html5-boilerplate/issues/440
audio,
canvas,
img,
svg,
video {
    vertical-align: middle;
}

img,
object,
embed {
    max-width: 100%;
    height: auto;
}

input {
    &::-webkit-inner-spin-button {
        display: none;
    }
}