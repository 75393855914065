$module: 'prodotti';

.#{$module} {
    padding: 1.5em 2em;
    overflow: hidden;
    max-width: 1280px;
    margin: 0 auto;

    &__title {
        font-family: 'Playfair Display', serif;
        text-align: center;
        margin: 1em auto 0.5em auto;
        text-transform: uppercase;
    }

    &__group {
        @include breakpoint(tablet-portrait) {
            display: flex;
            margin-bottom: 2em;
        }
    }

    &__img-container {

            &-1 {
                @include breakpoint(tablet-portrait) {
                    flex: 1 1 auto;
                    align-self: center;
                }
            }

            &-2 {
                @include breakpoint(tablet-portrait) {
                    flex: 1 1 auto;
                    align-self: center;
                }

            }
            .img:first-child {
                @include breakpoint(tablet-portrait) {
                    margin-bottom: 1em;
                }
            }

            .img__asset {
                cursor: pointer;

                @include breakpoint(desktop) {
                    max-width: 500px;
                }
            }

        .img {
            text-align: center;
            margin-bottom: 2em;

            @include breakpoint(tablet-portrait) {
                margin: 0 1em 0 0;
            }
        }
    }
}
