@mixin breakpoint($size) {
    @if $size == phone-only {
      @media (max-width: 599px) { @content; }
    } @else if $size == tablet-portrait {
      @media (min-width: 600px) { @content; }
    } @else if $size == tablet-landscape {
      @media (min-width: 992px) { @content; }
    } @else if $size == desktop {
      @media (min-width: 1280px) { @content; }
    } @else if $size == big-desktop {
      @media (min-width: 1800px) { @content; }
    }
  }

.desktopOnly {
  display: none !important;

  @include breakpoint(tablet-landscape) {
    display: block !important;
  };
}

.maxTablet {
    display: block !important;

    @include breakpoint(tablet-landscape) {
        display: none !important;
    };
}