$module: 'video';

.#{$module} {

    &__container {
        text-align: center;
        max-height: 600px;
        margin-bottom: 3em;
    }

    &__asset {
        object-fit: cover;
        width: 100%;
        height: 100%;

        @include breakpoint(tablet-landscape) {
            max-height: 600px;
            max-width: 900px;
        }

        &--loading {
            @include loading-placeholder(100%, auto);

            @include breakpoint(tablet-landscape) {
                @include loading-placeholder(900px, 500px)
            }
        }
    }
}
