$module: 'img';
$height-mobile: 200px;
$height-tablet-desktop: 400px;

.#{$module} {

    &--loading {
        @include loading-placeholder(100%, auto);

        @include breakpoint(tablet-landscape) {
            @include loading-placeholder(100%, auto)
        }
    }

    &__asset {
        max-width: 100%;
    }

    &.lazyload {
        min-height: $height-mobile;
        line-height: $height-mobile;

        @include breakpoint(tablet-landscape) {
            min-height: $height-tablet-desktop;
            line-height: $height-tablet-desktop;
        }
    }

    &--zoom-in-hover {
        overflow: hidden;

        img {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;

            &:hover {
                -webkit-transform: scale(1.3);
                transform: scale(1.3);
            }
        }
    }
}