$module: 'footer';

.#{$module} {
    background-color: $medium-grey;
    color: $white;
    display: flex;
    justify-content: space-around;
    padding: 1em 0;
    margin-top: 2em;

    &__title {
        text-transform: uppercase;
    }

    &__col {
        padding: 0 10px;
        flex: 0 1 auto;
    }

    &__par {
        font-size: 0.7em;
    }
}
