$module: 'button';

.#{$module} {
    text-align: center;
    padding-bottom: 1em;

    &.grey {
        background-color: $light-grey;
    }

    &__element {
        background-color: $black;
        color: $white;
        text-transform: uppercase;
        padding: 1em;
        font-size: 0.8em;
        cursor: pointer;
        display: inline-block;
    }
}