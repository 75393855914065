$module: 'navigationItem';

.#{$module} {
    margin: 1em 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    font-size: 1.1em;
    text-transform: uppercase;


    a {
        color: $black;

        &.active {
            border-bottom: 1px solid $black;
        }
        
        &:hover {
            border-bottom: 1px solid $black;
        }
    }
}