.burger {
    width: 2.5em;
    position: absolute;
    left: 1.5em;
    line-height: 1.3em;
    height: 100%;
    
    @include breakpoint(tablet-landscape){
        display: none;
    }

    &__container {
        height: 1em;
        margin-top: $header-height-mobile / 3;

        @include breakpoint(tablet-portrait) {
            margin-top: $header-height-tablet-desktop / 3;
        }
    }

    &__line {
        width: 100%;
        height: 2px;
        background-color: $col-text;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

        .active & {

            &:first-of-type {
                transform: rotate(45deg) translate(0, 0.6em);
            }
            &:last-of-type {
                transform: rotate(-45deg) translate(0, -0.6em);
            }
        }

        &:last-of-type {
            margin-top: 11px;
        }
    }
}