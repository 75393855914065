$module: 'home';

.#{$module} {
    margin: 0 auto;

    &__title {
        font-family: 'Playfair Display', serif;
        text-align: center;
        margin: 1em auto 0.5em auto;

        @include breakpoint(tablet-landscape) {
            margin-top: 2em;
        }
    }

    &__subtitle {
        text-align: center;
        margin-bottom: 1em;
        font-weight: normal;
    }

    .par {
        text-align: center;
    }

    .cta {
        margin-bottom: 1.5em;
    }

    .cta:first-of-type {
        background-color: $light-grey;

        .cta__col-img {
            padding-top: 1em;
            @include breakpoint(tablet-landscape) {
                padding: 0;
            }
        }

        .cta__container {
            @include breakpoint(tablet-landscape) {
                padding: 2em 0;
            }
        }

        .cta__container {
            @include breakpoint(desktop) {
                padding: 2em;
            }
        }
    }

    .cta:nth-of-type(2) {

        .cta__col-img {
            @include breakpoint(tablet-landscape) {
                order: 2;
            }
        }
    }

    .cta:nth-of-type(3) {
        .cta__container {
            background-color: $light-grey;
        }
    }

    .button__element:visited {
        color: $white;
    }

    .par__title-link:visited, .par__title-link {
        color: $black;
    }
}