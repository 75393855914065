.text {
    font-size: 1.3em;
}

.title {
    &-main {
        font-family: 'Playfair Display', serif;
        font-size: 2em;
        text-transform: uppercase;
        text-align: center;
        margin: 0.5em auto;
    }
}

.uppercase {
    text-transform: uppercase;
}

.wrap-inline {
    display: block;

    @include breakpoint(tablet-portrait) {
        display: flex;
        align-items: center;
    }
}