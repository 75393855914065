$module: 'cookie-banner';

.#{$module} {
  padding: 0.8em;
  background-color: #DADADA;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 4;
  transition: transform 0.7s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include breakpoint(tablet-portrait) {
    display: block;
  }

  &__text {
    margin-right: 0.5em;
  }

  &--hidden {
    transform: translateY(100%);
  }

  .button__element {
    width: 100px;
  }
}
