$module: 'par';

.#{$module} {

    padding: 1em;

    &__text {
        font-size: 1.3em;
    }

    &.grey {
        background-color: $light-grey;
    }

    &__title {
        font-family: 'Playfair Display', serif;
        font-size: 2em;
        text-transform: uppercase;
        margin-bottom: 0.3em;
    }
}
