$module: 'header';

.#{$module} {
    border-bottom: 1px solid $col-text;
    text-align: center;
    height: $header-height-mobile;
    background-color: $white;
    z-index: 4;
    position: sticky;
    top: 0;

    @include breakpoint(tablet-portrait) {
        height: $header-height-tablet-desktop;
    }

    @include breakpoint(tablet-landscape) {
        display: flex;
    }

    .icon-logo {
        font-size: 2em;
        display: inline-block;
        height: 100%;
        line-height: 1.5em;
        cursor: pointer;

        @include breakpoint(tablet-portrait) {
            font-size: 2.8em;
        }

        @include breakpoint(tablet-landscape) {
            flex: 0 1 20%;
        }

        &:visited {
            color: $col-text;
        }
    }

    .navigation {
        @include breakpoint('tablet-landscape') {
            flex: 1 1 auto;
        }
    }

    .icon-logo::before {
        width: 2em;
    }
}