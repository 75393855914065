$module: 'sidedrawer';

.#{$module} {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: $header-height-mobile;
    background-color: $white;
    padding: 2em 1.5em 4em 1.5em;
    box-sizing: border-box;
    transition: transform 0.3s ease;
    z-index: 4;

    &__nav {
        overflow: scroll;
        height: 100%;
    }

    &.open {
        transform: translateX(0);
    }
    
    &.close {
        transform: translateX(-100%);
    }

    .navigationItem {
        @include breakpoint(tablet-portrait) {
            font-size: 1.4em;
        }
    }

    @include breakpoint('desktop') {
        display: none;
    }
}

