$module: 'servizi';

.#{$module} {
    padding: 1.5em 2em;
    max-width: 1280px;
    margin: 0 auto;

    &__intro {
        padding: 0 1em;

        @include breakpoint(tablet-portrait) {
            padding: 0 2em;
        }
    }

    &__list {
        margin-top: 2em;
    }

    .img {
        text-align: center;
        margin-bottom: 1em;

        &__asset {
            max-width: 100%;

            @include breakpoint(tablet-landscape) {
                max-width: 450px;
            }

            @include breakpoint(desktop) {
                max-width: 600px;
            }
        }
    }
}