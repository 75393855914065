$module: 'date';

.#{$module} {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
    opacity: 0;

    @include breakpoint(tablet-landscape) {
        bottom: 50%;
        left: 50%;
        width: 150px;
    }

    &--fadein {
        opacity: 1;
        transition: opacity 0.7s ease-in, transform 1s ease-in;
        transform: translate(-50%, 50%);
    }

    &__container {
        background-color: $white;
        text-align: center;
        position: relative;
        padding: 5px;

        @include breakpoint(tablet-portrait) {
            line-height: 3em;
        }

        @include breakpoint(tablet-landscape) {
            line-height: 4em;
        }
    }

    &-after {
        position: absolute;
        left: 0;
    }

    &__number {
        display: inline-block;
        font-family: 'Playfair Display', serif;
        font-size: 2em;

        @include breakpoint(tablet-portrait) {
            font-size: 3em;
        }

        @include breakpoint(tablet-landscape) {
            font-size: 3.5em;
        }
    }
}