$module: 'cta';

.#{$module} {
    text-align: center;

    .img__asset {
        max-width: 100%;

        @include breakpoint(tablet-landscape) {
            max-width: 600px;
        }

        @include breakpoint(desktop) {
            max-width: 800px;
        }
    }

    &__container {
        align-items: center;
        margin: 2em 1em;

        @include breakpoint(tablet-landscape) {
            display: flex;
            max-width: 900px;
            margin: 0 auto;
            padding: 0;
        }

        @include breakpoint(desktop) {
            max-width: 1280px;
        }
    }

    &__col {
        flex: 1 1 auto;

        &-img {
            flex: 1 1;
        }
    }
}