$module: 'storia';

.#{$module} {
    .img {
        &__asset {
            max-height: 550px;
            width: 100%;
            object-fit: cover;

            @include breakpoint(tablet-portrait) {
                object-fit: cover;
                width: 100%;
                max-height: none;
            }

            @include breakpoint(tablet-landscape) {
                height: calc(100vh - #{$header-height-tablet-desktop});
            }
        }
    }

    .section__container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;

        @include breakpoint(tablet-landscape) {
            flex-wrap: nowrap;
        }

        &-image {
            position: relative;
            flex: 1 1 100%;

            @include breakpoint(tablet-landscape) {
                flex: 1 1 50%;
            }

        }
    }

    .text {
        flex: 1 1 100%;
        padding: 2em;

        @include breakpoint(tablet-portrait) {
            padding: 2em;
        }
        @include breakpoint(tablet-landscape) {
            padding: 0 1em 0 100px;
            flex: 1 1 50%;
        }
        @include breakpoint(desktop) {
            font-size: 1.3em;
        }
    }

    ~ .footer {
        margin-top: 0;
    }
}