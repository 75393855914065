@mixin plus-icon($width, $color) {
    display: inline-block;
    width: $width;
    height: $width;
    border: 1px solid $color;
    border-radius: 50%;
    position: relative;
    transition: transform 0.5s;

    &:before {
        content: '';
        height: 1px;
        width: $width - 13px;
        background: $color;
        margin: 0 auto;
        position: absolute;
        display: inline-block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:after {
        content: '';
        width: 1px;
        height: $width - 13px;
        background: $color;
        position: absolute;
        display: inline-block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
    }
}

@keyframes placeHolderShimmer{
    0% {
        opacity: 0.4;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.4;
    }
}

@mixin loading-placeholder($width, $height) {
    width: $width;
    height: $height;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: $loading-grey;
}